import React from 'react'
import { Col, Row } from 'reactstrap'

const Community = () => {
    return (
        <>
            <section id="community">
                <h4 className='text-center text-white mb-0 ff-semibold'>JOIN</h4>
                <h1 className='text-center text-red mb-0  ff-bold'>JKICKS</h1>
                <h1 className='text-center text-red  ff-bold'>COMMUNITY</h1>
                <Row className='justify-content-lg-center align-items-lg-center'>
                    <Col md="6">
                        <div>
                            <div className='bg-community-box d-flex align-items-center justify-content-center' data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <div className='d-flex align-items-center justify-content-between p-3'>
                                    <div className='img-width'>
                                        <img src={require('../../assets/img/Landing_Page/mission.png').default} className="img-fluid" />
                                    </div>
                                    <div className='ml-3'>
                                        <h3 className='text-uppercase mb-0 ff-semibold text-white'>Our mission</h3>
                                        <p className='ff-regular text-white font-para'>Orchestrate a community with strong connections and appreciated art to empower sneakerheads</p>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-community-box d-flex align-items-center justify-content-center mt-3' data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <div className='d-flex align-items-center justify-content-between p-3'>
                                    <div className='img-width'>
                                        <img src={require('../../assets/img/Landing_Page/nft.png').default} className="img-fluid" />
                                    </div>
                                    <div className='ml-3'>
                                        <h3 className='text-uppercase mb-0 ff-semibold text-white'>Our NFT</h3>
                                        <p className='ff-regular text-white font-para'>The JKicks NFTs serve as a token of membership and access to special benefits & communications for trading sneakers</p>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-community-box d-flex align-items-center justify-content-center mt-3' data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <div className='d-flex align-items-center justify-content-between p-3'>
                                    <div className='img-width'>
                                        <img src={require('../../assets/img/Landing_Page/buyers.png').default} className="img-fluid" />
                                    </div>
                                    <div className='ml-3'>
                                        <h3 className='text-uppercase mb-0 ff-semibold text-white'>Buyers</h3>
                                        <p className='ff-regular text-white font-para'>Get first access to exclusive deals, promotions, and sales from our growing network of partner sellers</p>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-community-box d-flex align-items-center justify-content-center mt-3' data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <div className='d-flex align-items-center justify-content-between p-3'>
                                    <div className='img-width'>
                                        <img src={require('../../assets/img/Landing_Page/sellers.png').default} className="img-fluid" />
                                    </div>
                                    <div className='ml-3'>
                                        <h3 className='text-uppercase mb-0 ff-semibold text-white'>Sellers</h3>
                                        <p className='ff-regular text-white font-para'>Get to know your customers and enhance your marketing efforts. Find buyers for your shoes while keeping up with trends in demand</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" className='d-flex flex-column flex-lg-row justify-content-xl-center'>
                        <div className='text-center mt-4'>
                            <a href="https://discord.gg/zuRKzGXpWZ" target={'_blank'} className="text-white"><button className="round-btn">Join The Community</button></a>
                        </div>
                        <img src={require('../../assets/img/Landing_Page/community-right.png').default} className="img-fluid" data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1500" />
                    </Col>
                </Row>
            </section>
        </>
    )
}

export default Community