/*!

=========================================================
* exceed - v1.0.0
=========================================================
* Coded by Ecrubit
*/
import React from "react";
// reactstrap components
import {
    Row,
    Col
} from "reactstrap";
import { FaDiscord, FaTwitter } from "react-icons/fa";

export default function Footer() {
    return (
        <section className="py-5" data-aos="zoom-in-up">
            <div className="container mt-5 border-bottom-style">
                <Row className="pb-3 justify-content-center align-items-center">
                    <Col xl="3" lg="2" md="12" sm="12" xs="12" className="mt-3 mt-lg-0 d-flex flex-row justify-content-center justify-content-lg-start">
                        <a href="#"><img src={require('../../assets/img/logo.png').default} alt='logo' className='img-fluid' width={120} /></a>
                    </Col>
                    <Col xl="6" lg="7" md="12" sm="12" xs="12" className="mt-3 mt-lg-0">
                        <div className="nav-link-space footer-text d-flex flex-column flex-lg-row align-items-center justify-content-center text-primary">
                            <a href="/#collection" className="text-white ff-regular font-para px-3">Collection</a>
                            <a href="/#about" className="text-white ff-regular font-para px-3">About The Project</a>
                            <a href="/#community" className="text-white ff-regular font-para px-3">Community</a>
                            <a href="/#roadmap" className="text-white ff-regular font-para px-3">Roadmap</a>
                        </div>
                    </Col>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="mt-3 mt-lg-0">
                        <div className="social-div d-flex flex-row justify-content-center justify-content-lg-end">
                            <a href="https://twitter.com/JKicksSGC" target={'_blank'} rel='noreferrer'><FaTwitter size="35" color="white" className="icon-rounded" /></a>
                            <a href="https://discord.gg/zuRKzGXpWZ" target={'_blank'} rel='noreferrer'><FaDiscord size="35" color="white" className="icon-rounded" /></a>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="ff-regular text-center text-white pt-5">© Copyright© 2023  |  All Rights Reserved.</div>
        </section >
    );
}
