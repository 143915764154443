/*!

=========================================================
* exceed - v1.0.0
=========================================================
* Coded by Ecrubit
*/
import React from "react";
import { Link } from "react-router-dom";
import { AnchorScroll } from "helper";
// reactstrap components
import {

  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Row,
  Col
} from "reactstrap";
import { FaTimes } from "react-icons/fa";
import { FiMenu } from 'react-icons/fi';


export default function IndexNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };

  return (
    <Navbar expand="lg" className="bg-nav">
      <div className="w-100">
        <Row className="d-flex align-items-center justify-content-center">
          <Col lg="2">
            <div>
              <NavbarBrand className="mt-2" to="/" tag={Link} id="navbar-brand" onClick={() => AnchorScroll()}>
                <img src={require('../../assets/img/logo.png').default} alt='logo' className='img-fluid' width={120} />
              </NavbarBrand>
              <button
                aria-expanded={collapseOpen}
                className="navbar-toggler navbar-toggler"
                onClick={toggleCollapse}
              >
                <FiMenu className="text-white" size="24" />
              </button>
            </div>
          </Col>
          <Col lg="7" className="text-lg-center">
            <Collapse
              className={"ml-4 justify-content-center " + collapseOut}
              navbar
              isOpen={collapseOpen}
              onExiting={onCollapseExiting}
              onExited={onCollapseExited}
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column flex-lg-row justify-content-between">
                  <Nav className="nav-link-space d-flex flex-column flex-lg-row">
                    <NavItem>
                      <a href="/#collection" className="text-white ff-regular font-para px-3">Collection</a>
                    </NavItem>
                    <NavItem>
                      <a href="/#about" className="text-white ff-regular font-para px-3">About The Project</a>
                    </NavItem>
                    <NavItem>
                      <a href="/#community" className="text-white ff-regular font-para px-3">Community</a>
                    </NavItem>
                    <NavItem>
                      <a href="/#roadmap" className="text-white ff-regular font-para px-3">Roadmap</a>
                    </NavItem>
                  </Nav>
                </div>
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-close" xs="6">
                      <button
                        aria-expanded={collapseOpen}
                        onClick={toggleCollapse}
                      >
                        <FaTimes className="pf-text-secondary" size="24" />
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
              <a href="https://discord.gg/zuRKzGXpWZ" target={'_blank'} className="text-white"><button className="btn-primary d-block d-lg-none py-2 px-4 mt-4 mt-lg-0 ff-semibold">Join The Community</button></a>
            </Collapse>
          </Col>
          <Col lg="3" className="text-right d-none d-lg-flex justify-content-end">
            <a href="https://discord.gg/zuRKzGXpWZ" target={'_blank'} className="text-white"><button className="btn-primary py-2 px-4 mt-4 mt-lg-0 ff-semibold">Join The Community</button></a>
          </Col>
        </Row>
      </div>
    </Navbar>
  );
}
