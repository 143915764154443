import React from 'react'
import { Col, Row } from 'reactstrap'

const Roadmap = () => {
    return (
        <>
            <section id="roadmap">
                <h4 className='text-center text-white mb-0 ff-semibold'>ROAD</h4>
                <h1 className='text-center text-red mb-0 ff-bold'>MAP</h1>
                <Row className='justify-content-center align-items-center mt-5'>
                    <Col lg="3" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000">
                        <div className='d-flex flex-column align-items-center'>
                            <div className='round-map d-flex align-items-center justify-content-center mb-4'>
                                <img src={require('../../assets/img/Landing_Page/map1.png').default} className="img-fluid" width={150} />
                            </div>
                            <h4 className='text-uppercase mb-0 text-white text-center ff-bold'>September 2022</h4>
                            <p className='text-white text-center ff-regular font-para'>JKicks is hypothesized and the team started on the artwork</p>
                        </div>
                    </Col>
                    <Col lg="3" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000">
                        <div className='mt-5 pt-lg-5 d-flex flex-column align-items-center'>
                            <div className='round-map d-flex align-items-center justify-content-center mb-4'>
                                <img src={require('../../assets/img/Landing_Page/map2.png').default} className="img-fluid" width={150} />
                            </div>
                            <h4 className='text-uppercase mb-0 text-white text-center ff-bold' >December 2022</h4>
                            <p className='text-white text-center ff-regular font-para'>Community utility was confirmed</p>
                        </div>
                    </Col>
                    <Col lg="3" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000">
                        <div className='mt-5 mt-lg-0 d-flex flex-column align-items-center'>
                            <div className='round-map d-flex align-items-center justify-content-center mb-4'>
                                <img src={require('../../assets/img/Landing_Page/map3.png').default} className="img-fluid" width={150} />
                            </div>
                            <h4 className='text-uppercase mb-0 text-white text-center ff-bold'>March 2023</h4>
                            <p className='text-white text-center ff-regular font-para'>The team launches J Kicks offline, partnering with select buyers and sellers to prepare for launch to the public</p>
                        </div>
                    </Col>
                    <Col lg="3" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000">
                        <div className='mt-5 pt-lg-5 d-flex flex-column align-items-center'>
                            <div className='round-map d-flex align-items-center justify-content-center mb-4'>
                                <img src={require('../../assets/img/Landing_Page/map4.png').default} className="img-fluid" width={150} />
                            </div>
                            <h4 className='text-uppercase mb-0 text-white text-center ff-bold'>June 2023</h4>
                            <p className='text-white text-center ff-regular font-para'>Official launch.</p>
                        </div>
                    </Col>
                </Row>
            </section>
        </>
    )
}

export default Roadmap