import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap';

const NFT = () => {

    const [timerDays, setTimerDays] = useState(0);
    const [timerHours, setTimerHours] = useState(0);
    const [timerMinutes, setTimerMinutes] = useState(0);
    const [timerSeconds, setTimerSeconds] = useState(0);
    let interval;

    const startTimer = () => {
        const countdownDate = new Date('June 10, 2023 02:30:00').getTime();

        interval = setInterval(() => {
            const now = Date.now();
            const difference = countdownDate - now;

            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60)

            if (difference < 0) {
                clearInterval(interval.current)
            }
            else {
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
            }
        }, 1000)
    };

    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current)
        }
    })
    return (
        <section id="collection">
            <h4 className='text-center text-white ff-semibold'>NFTS</h4>
            <h1 className='text-center text-red ff-bold'>MINT</h1>
            <div className="timer-sec d-flex flex-row justify-content-center align-items-center">
                <div data-aos="zoom-in" className="timer d-flex flex-column justify-content-center align-items-center mx-1 mx-sm-3"><div className="ff-medium">{timerDays < 10 ? "0" + timerDays : timerDays}</div> <div className="mt-2 text-light-black">DAYS</div></div>
                <div data-aos="zoom-in" className="timer d-flex flex-column justify-content-center align-items-center mx-1 mx-sm-3"><div className="ff-medium">{timerHours < 10 ? "0" + timerHours : timerHours}</div> <div className="mt-2 text-light-black">HOURS</div></div>
                <div data-aos="zoom-in" className="timer d-flex flex-column justify-content-center align-items-center mx-1 mx-sm-3"><div className="ff-medium">{timerMinutes < 10 ? "0" + timerMinutes : timerMinutes}</div> <div className="mt-2 text-light-black">MINUTES</div></div>
                <div data-aos="zoom-in" className="timer d-flex flex-column justify-content-center align-items-center mx-1 mx-sm-3"><div className="ff-medium">{timerSeconds < 10 ? "0" + timerSeconds : timerSeconds}</div> <div className="mt-2 text-light-black">SECONDS</div></div>
            </div>
            <div className='mt-5'>
                <Row className='d-flex align-items-center justify-content-center'>
                    <Col sm="1"></Col>
                    <Col sm="2" className='text-center mt-3' data-aos="flip-left">
                        <img src={require('../../assets/img/Landing_Page/nft1.png').default} className="img-fluid" />
                    </Col>
                    <Col sm="2" className='text-center mt-3' data-aos="flip-left">
                        <img src={require('../../assets/img/Landing_Page/nft2.png').default} className="img-fluid" />
                    </Col>
                    <Col sm="2" className='text-center mt-3' data-aos="flip-left">
                        <img src={require('../../assets/img/Landing_Page/nft3.png').default} className="img-fluid" />
                    </Col>
                    <Col sm="2" className='text-center mt-3' data-aos="flip-left">
                        <img src={require('../../assets/img/Landing_Page/nft4.png').default} className="img-fluid" />
                    </Col>
                    <Col sm="2" className='text-center mt-3' data-aos="flip-left">
                        <img src={require('../../assets/img/Landing_Page/nft5.png').default} className="img-fluid" />
                    </Col>
                    <Col sm="1"></Col>
                    <Col sm="1"></Col>
                    <Col sm="2" className='text-center mt-3' data-aos="flip-left">
                        <img src={require('../../assets/img/Landing_Page/nft6.png').default} className="img-fluid" />
                    </Col>
                    <Col sm="2" className='text-center mt-3' data-aos="flip-left">
                        <img src={require('../../assets/img/Landing_Page/nft7.png').default} className="img-fluid" />
                    </Col>
                    <Col sm="2" className='text-center mt-3' data-aos="flip-left">
                        <img src={require('../../assets/img/Landing_Page/nft8.png').default} className="img-fluid" />
                    </Col>
                    <Col sm="2" className='text-center mt-3' data-aos="flip-left">
                        <img src={require('../../assets/img/Landing_Page/nft9.png').default} className="img-fluid" />
                    </Col>
                    <Col sm="2" className='text-center mt-3' data-aos="flip-left">
                        <img src={require('../../assets/img/Landing_Page/nft10.png').default} className="img-fluid" />
                    </Col>
                    <Col sm="1"></Col>
                </Row>
            </div>
        </section>
    )
}

export default NFT