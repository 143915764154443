import React from 'react'
import { Col, Row } from 'reactstrap'

const About = () => {
    return (
        <>
            <section id="about">
                <Row className='d-flex align-items-center justify-content-center'>
                    <Col md="6" className='order-2 order-md-1 mt-5 mt-md-0' data-aos="zoom-in-up">
                        <img src={require('../../assets/img/Landing_Page/about-left.png').default} className="img-fluid" />
                    </Col>
                    <Col md="6" className='order-1 order-md-2' data-aos="zoom-out">
                        <h4 className='text-center text-white ff-semibold'>ABOUT</h4>
                        <h1 className='text-center text-red ff-bold'>JKICKS</h1>
                        <p className='text-center text-white ff-regular font-para'>Sea Grass Cryptics is a collective of young professionals and creatives dedicated to building real utility and connections through our NFTs. Our latest project, J-Kicks, is committed to doing just that for the sneaker community. Over 4,500 unique PFP color schemes of the iconic Jordan-1 silhouette are accompanied by exclusive access to our online community, sneaker giveaways, and special discounts to select partner stores in your area!</p>
                        <p className='text-center text-white ff-regular font-para'>While J-Kicks will serve as an online home for sneakerheads and the greater crypto community alike- each NFT transaction will also contribute a portion towards charities giving footwear to underprivileged children worldwide.</p>
                        <div className='text-center mt-4'>
                            <a href="https://discord.gg/zuRKzGXpWZ" target={'_blank'} className="text-white"><button className="round-btn">Join The Community</button></a>
                        </div>
                    </Col>
                </Row>
            </section>
        </>
    )
}

export default About