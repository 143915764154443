import React from 'react'
import { Col, Row } from 'reactstrap'

const Access = () => {
    return (
        <>
            <section className='py-5'>
                <div className='bg-access' data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1500">
                    <Row className='align-items-center justify-content-center p-3'>
                        <Col md="4" xl="5" className='order-2 order-md-1'>
                            <img src={require('../../assets/img/Landing_Page/footer-shoe.png').default} className="img-fluid" />
                        </Col>
                        <Col md="3" xl="2" className='order-3 order-md-2'>
                            <div className='text-center'>
                                <a href="https://discord.gg/zuRKzGXpWZ" target={'_blank'} className="text-white"><button className="round-btn">Join The Community</button></a>
                            </div>
                        </Col>
                        <Col md="5" xl="5" className='order-1 order-md-3'>
                            <h3 className='ff-bold text-uppercase text-white text-center'>Exclusive access for sneaker giveaways and deals. The JKicks community makes buying & selling easier.</h3>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}

export default Access