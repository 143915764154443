import { Row, Col } from "reactstrap";
import IndexNavbar from "components/Navbars/IndexNavbar";

export default function Banner() {
    return (
        <section className="py-0">
            <div>
                <IndexNavbar className="fixed-top" />
            </div>
            <div className="mt-md-5">
                <div className="banner-mobile pt-0">
                    <Row className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <Col xl="7" lg="12" md="12" sm="12" xs="12" className="text-center text-xl-left" data-aos="fade-up">
                            <div><span className="banner-text mb-0 mr-1">WELCOME TO</span><img src={require('../../assets/img/Landing_Page/banner-foot.png').default} className="img-fluid mb-4" width={400} /><span className="banner-text mb-0">JKICKS</span></div>
                            <a href="https://discord.gg/zuRKzGXpWZ" target={'_blank'} className="text-white"><button className="round-btn">Join The Community</button></a>
                        </Col>
                        <Col xl="5" lg="12" md="12" sm="12" xs="12" className="banner-image">
                            <img src={require('../../assets/img/Landing_Page/banner-right.png').default} className="img-fluid" />
                        </Col>
                    </Row>
                </div>
            </div>
        </section>

    )
}