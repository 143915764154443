import Footer from "components/Footer";
import React from "react";
import About from "./About";
import Access from "./Access";
import Banner from "./Banner";
import Community from "./Community";
import NFT from "./NFT";
import Roadmap from "./Roadmap";

export default function Index() {
    React.useEffect(() => {
        document.body.classList.toggle("index-page");
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.body.classList.toggle("index-page");
        };

    }, []);
    return (
        <>
            <div className="wrapper" >
                <Banner />
                <NFT />
                <About />
                <Community />
                <Roadmap />
                <Access />
                <Footer />
            </div>
        </>
    );
}